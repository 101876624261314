<template>
    <section>
      <div class="container.is-widescreen" style="padding-top:18px">

            <h1 class="title" style="color:#3e8ed0">Received messages</h1>
            <h4 class="subtitle">See all received messages by project.</h4>
            <div class="columns" style="padding-left: 14px; padding-top: 10px; padding-bottom: 15px;">
                <div style="padding-right: 20px;">
              <b-field label="Project:">
                <b-select v-model="projectId" expanded>
              <option v-for="(value, key) in showProjects" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
    </b-field>
  </div>
<div style="padding-right: 20px;">
            <b-field label="Date range:">
                <b-datepicker
                v-model="dates"
                :locale="locale"
                icon="calendar-today"
                placeholder="Click to select..."
                range
                trap-focus>
            </b-datepicker>
    </b-field>
  </div>
  <div style="color: white">
 <b-field label="/" class="foo"><b-button type="is-primary" @click="searchClicked">Search</b-button></b-field>
</div>

        </div>
        <div style="width: 99%;">
        <b-table v-if="showData && tableData.length > 0" :data="tableData" :columns="columns" :bordered="isBordered" :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"></b-table>
</div>
            <div v-if="showData && tableData.length === 0">No results.</div>

            <div v-if="showData && tableData.length > 0"  class="columns" style="padding-left: 14px; padding-top: 30px; padding-bottom: 15px;">
            <div style="padding-right: 20px;">

              <b-dropdown :triggers="['hover']" position="is-top-left" aria-role="list">
            <template #trigger>
                <b-button
                    label="Export data"
                    type="is-info"
                    icon-right="menu-up" />
            </template>

            <b-dropdown-item aria-role="listitem">
              <download-excel :data="tableData" :fields="jsonFields" name="ReceivedMessagesByProject.csv" type="csv" :escapeCsv="falseString">
  CSV
</download-excel>
</b-dropdown-item>
             
            <b-dropdown-item aria-role="listitem">
              <download-excel :data="tableData" :fields="jsonFields" name="ReceivedMessagesByProject.xls">
  Excel
</download-excel>
</b-dropdown-item>
        </b-dropdown>
            </div>
            </div>

</div>
    </section>
  </template>
  
<script>
  import { mapActions, mapGetters } from 'vuex'
  import axios from 'axios'

  export default {
    name: 'ReceivedMessages',
    computed: mapGetters(['getRole', 'getWorkspaceId', 'getAuthHeader', 'allProjects']),
    created: function () {
      if (this.getRole !== 'Super Admin') {
        // this.fetchPrizeProjectsByWorkspaceId(this.getWorkspaceId)
        this.fetchProjectsByWorkspaceId(this.getWorkspaceId)
      } else {
        // this.fetchPrizeProjects()
        this.fetchProjects()
      }
      this.showProjects = this.allProjects
      this.showProjects = this.showProjects.filter(x => x.projectType === 'Prize Code' || x.projectType === 'Prize Pattern'  || x.projectType === 'Prize External')
    },
    data () {
      return {
        falseString: false,
        showProjects: [],
        perPage: 20,
        projectId: null,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        currentPage: 1,
        tableData: null,
        showData: false,
        dates: [],
        locale: 'en-GB',
        isBordered: true,
        isPaginated: true,
        columns: [
            {
                field: '_id',
                label: 'ID',
                // width: '40',
                // numeric: true
                centered: true
            },
            {
                field: 'date',
                label: 'Date',
                centered: true
            },
            {
                field: 'messageText',
                label: 'Message text',
                centered: true
            },
            {
                field: 'originator',
                label: 'Originator',
                centered: true
            },
            {
                field: 'destination',
                label: 'Destination',
                centered: true
            },
            {
                field: 'messageId',
                label: 'Message ID',
                centered: true
            },
            {
                field: 'networkName',
                label: 'Network name',
                centered: true
            }
        ],
        jsonFields: {
          "ID": "_id",
          "Date": "date",
          "Message text": "messageText",
          "Originator": "originator",
          "Destination": "destination",
          "Message ID": "messageId",
          "Network name": "networkName"
        },
      }
    },
    methods: {
      ...mapActions(['fetchPrizeProjects', 'fetchPrizeProjectsByWorkspaceId', 'fetchProjects', 'fetchProjectsByWorkspaceId']),
      async searchClicked () {
        if (this.dates.length !== 2 || this.projectId === null) {
          this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Please fill in all fields.',
              confirmText: 'Ok!',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa'
          })
        } else {
          var loadingComponent = this.$buefy.loading.open()
          let startDateOriginal = this.dates[0].toLocaleDateString('en-GB')
          let endDateOriginal = this.dates[1].toLocaleDateString('en-GB')
          var startDateArray = startDateOriginal.split('/')
          var endDateArray = endDateOriginal.split('/')
          var startDate = startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0]
          var endDate = endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0]
          // var sender = this.allProjects.filter(x => x._id === this.projectId)[0].sender
          this.globalLogger(startDate, endDate)
          try {
            const response = await axios.post('https://smsgw.virtual-service-center.com:8080/analytics/getMessageReceived/' + this.projectId, {
              startDate: startDate,
              endDate: endDate
            }, {
                headers: { 'Authorization': this.getAuthHeader }
            })
            loadingComponent.close()
            this.tableData = response.data
            this.showData = true
          } catch (error) {
            loadingComponent.close()
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to fetch data.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
          }
        }
      }
    }
  }
</script>

<style>
</style>