<template>
    <section>
      <div v-if="getRole === 'Super Admin'" class="container.is-widescreen" style="padding-top:18px">
            <h1 class="title" style="color:#3e8ed0">All messages</h1>
            <h4 class="subtitle">See all messages in a date range.</h4>
            <div class="columns" style="padding-left: 14px; padding-top: 10px; padding-bottom: 15px;">

              <div style="padding-right: 20px;">
              <b-field label="Message type:">
                <b-select v-model="messageType" expanded>
                  <option value="sent">Sent & forwarded</option>
                  <option value="received">Received</option>
            </b-select>
    </b-field>
  </div>
            
<div style="padding-right: 20px;">
            <b-field label="Date range:">
                <b-datepicker
                v-model="dates"
                :locale="locale"
                icon="calendar-today"
                placeholder="Click to select..."
                range
                trap-focus>
            </b-datepicker>
    </b-field>
  </div>
  <div style="color: white">
 <b-field label="/" class="foo"><b-button type="is-primary" @click="searchClicked">Search</b-button></b-field>
</div>

        </div>
        <div style="width: 99%;">
        <b-table v-if="showData && tableData.length > 0" :data="tableData" :columns="columns" :bordered="isBordered" :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"></b-table>
</div>
            <div v-if="showData && tableData.length === 0">No results.</div>

            <div style="width: 99%;">
        <b-table v-if="showDataReceived && tableDataReceived.length > 0" :data="tableDataReceived" :columns="columnsReceived" :bordered="isBordered" :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"></b-table>
</div>
            <div v-if="showDataReceived && tableDataReceived.length === 0">No results.</div>


            <div v-if="(showDataReceived && tableDataReceived.length > 0) || (showData && tableData.length > 0)"  class="columns" style="padding-left: 14px; padding-top: 30px; padding-bottom: 15px;">
            <div style="padding-right: 20px;">

              <b-dropdown :triggers="['hover']" position="is-top-left" aria-role="list">
            <template #trigger>
                <b-button
                    label="Export data"
                    type="is-info"
                    icon-right="menu-up" />
            </template>

            <b-dropdown-item aria-role="listitem">
              <download-excel :data="tableDataExport" :fields="jsonFields" :name="nameExportCsv" type="csv" :escapeCsv="falseString">
  CSV
</download-excel>
</b-dropdown-item>
             
            <b-dropdown-item aria-role="listitem">
              <download-excel :data="tableDataExport" :fields="jsonFields" :name="nameExportXlsx">
  Excel
</download-excel>
</b-dropdown-item>
        </b-dropdown>
            </div>
            </div>

</div>
    </section>
  </template>
  
<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'

  export default {
    name: 'SeeMessages',
    computed: mapGetters(['getRole', 'getAuthHeader']),
    data () {
      return {
        falseString: false,
        showDataReceived: false,
        messageType: null,
        perPage: 20,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        currentPage: 1,
        tableData: null,
        tableDataReceived: null,
        tableDataExport: null,
        showData: false,
        nameExportCsv: '',
        nameExportXlsx: '',
        dates: [],
        locale: 'en-GB',
        isBordered: true,
        isPaginated: true,
        columns: [
            {
                field: '_id',
                label: 'ID',
                // width: '40',
                // numeric: true
                centered: true
            },
            {
                field: 'time',
                label: 'Time',
                centered: true
            },
            {
                field: 'text',
                label: 'Message text',
                centered: true
            },
            {
                field: 'status',
                label: 'Status',
                centered: true
            },
            {
                field: 'sender',
                label: 'Sender',
                centered: true
            },
            {
                field: 'receiver',
                label: 'Receiver',
                centered: true
            },
            {
                field: 'type',
                label: 'Type',
                centered: true
            },
            {
                field: 'size',
                label: 'Size',
                centered: true
            }
        ],
        columnsReceived: [
            {
                field: '_id',
                label: 'ID',
                // width: '40',
                // numeric: true
                centered: true
            },
            {
                field: 'date',
                label: 'Date',
                centered: true
            },
            {
                field: 'messageText',
                label: 'Message text',
                centered: true
            },
            {
                field: 'originator',
                label: 'Originator',
                centered: true
            },
            {
                field: 'destination',
                label: 'Destination',
                centered: true
            },
            {
                field: 'messageId',
                label: 'Message ID',
                centered: true
            },
            {
                field: 'networkName',
                label: 'Network name',
                centered: true
            }
        ],
        jsonFieldsSent: {
          "ID": "_id",
          "Time": "time",
          "Message text": "text",
          "Status": "status",
          "Sender": "sender",
          "Receiver": "receiver",
          "Type": "type",
          "Size": "size"
        },
        jsonFieldsReceived: {
          "ID": "_id",
          "Date": "date",
          "Message text": "messageText",
          "Originator": "originator",
          "Destination": "destination",
          "Message ID": "messageId",
          "Network name": "networkName"
        },
        jsonFields: null
      }
    },
    methods: {
      async searchClicked () {
        if (this.dates.length !== 2 || this.messageType === null) {
          this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Please fill in all fields.',
              confirmText: 'Ok!',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa'
          })
        } else {
          var loadingComponent = this.$buefy.loading.open()
          let startDateOriginal = this.dates[0].toLocaleDateString('en-GB')
          let endDateOriginal = this.dates[1].toLocaleDateString('en-GB')
          var startDateArray = startDateOriginal.split('/')
          var endDateArray = endDateOriginal.split('/')
          var startDate = startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0]
          var endDate = endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0]
          this.globalLogger(startDate, endDate)
          if (this.messageType === 'sent') {
            try {
              const response = await axios.post('https://smsgw.virtual-service-center.com:8080/analytics/allSentMessages', {
                startDate: startDate,
                endDate: endDate
              }, {
                  headers: { 'Authorization': this.getAuthHeader }
              })
              loadingComponent.close()
              this.tableData = response.data
              var time = null
              var timeArray = null
              for (let obj in response.data) {
                time = this.tableData[obj].time
                timeArray = time.split('T')
                this.tableData[obj].time = timeArray[0] + ' ' + timeArray[1].substring(0, 8)
              }
              this.globalLogger(this.tableData, 'table')
              this.showData = true
              this.showDataReceived = false
              this.tableDataExport = this.tableData
              this.nameExportCsv = 'AllSentMessages.csv'
              this.nameExportXlsx = 'AllSentMessages.xls'
              this.jsonFields = this.jsonFieldsSent
            } catch (error) {
              loadingComponent.close()
              this.$buefy.dialog.alert({
                  title: 'Error',
                  message: 'An error occured while trying to fetch data.',
                  confirmText: 'Ok!',
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa'
              })
            }   
          } else {
            try {
              const response = await axios.post('https://smsgw.virtual-service-center.com:8080/analytics/allReceivedMessages', {
                startDate: startDate,
                endDate: endDate
              }, {
                  headers: { 'Authorization': this.getAuthHeader }
              })
              loadingComponent.close()
              this.tableDataReceived = response.data
              var time1 = null
              var timeArray1 = null
              for (let obj in response.data) {
                time1 = this.tableDataReceived[obj].date
                timeArray1 = time1.split('T')
                this.tableDataReceived[obj].date = timeArray1[0] + ' ' + timeArray1[1].substring(0, 8)
              }
              this.globalLogger(this.tableDataReceived, 'table received')
              this.showDataReceived = true
              this.showData = false
              this.tableDataExport = this.tableDataReceived
              this.nameExportCsv = 'AllReceivedMessages.csv'
              this.nameExportXlsx = 'AllReceivedMessages.xls'
              this.jsonFields = this.jsonFieldsReceived
            } catch (error) {
              loadingComponent.close()
              this.$buefy.dialog.alert({
                  title: 'Error',
                  message: 'An error occured while trying to fetch data.',
                  confirmText: 'Ok!',
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa'
              })
            }
          }
        }
      }
    }
  }
</script>

<style>
</style>