<template>
    <section>
      <div class="container.is-widescreen" style="padding-top:18px">

            <h1 class="title" style="color:#3e8ed0">Sent messages</h1>
            <h4 class="subtitle">See all sent messages by project.</h4>
            <div class="columns" style="padding-left: 14px; padding-top: 10px; padding-bottom: 15px;">
                <div style="padding-right: 20px;">
              <b-field label="Project:">
                <b-select v-model="projectId" expanded>
              <option v-for="(value, key) in showProjects" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
    </b-field>
  </div>
<div style="padding-right: 20px;">
            <b-field label="Date range:">
                <b-datepicker
                v-model="dates"
                :locale="locale"
                icon="calendar-today"
                placeholder="Click to select..."
                range
                trap-focus>
            </b-datepicker>
    </b-field>
  </div>
  <div style="padding-right: 20px;">
              <b-field label="Message status:">
                <b-select v-model="status" expanded>
                    <option key="0" value="all">All</option>
                    <option key="1" value="success">Successful</option>
                    <option key="2" value="fail">Unsuccessful</option>
            </b-select>
    </b-field>
  </div>
  <div style="color: white">
 <b-field label="/" class="foo"><b-button type="is-primary" @click="searchClicked">Search</b-button></b-field>
</div>

        </div>
        <div style="width: 99%;">
        <b-table v-if="showData && tableData.length > 0" :data="tableData" :columns="columns" :bordered="isBordered" :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"
            ></b-table>
          </div>
            <div v-if="showData && tableData.length === 0">No results.</div>


            <div v-if="showData && tableData.length > 0"  class="columns" style="padding-left: 14px; padding-top: 30px; padding-bottom: 15px;">
            <div style="padding-right: 20px;">

              <b-dropdown :triggers="['hover']" position="is-top-left" aria-role="list">
            <template #trigger>
                <b-button
                    label="Export data"
                    type="is-info"
                    icon-right="menu-up" />
            </template>

            <b-dropdown-item aria-role="listitem">
              <download-excel :data="tableData" :fields="jsonFields" name="SentMessagesByProject.csv" type="csv" :escapeCsv="falseString">
  CSV
</download-excel>
</b-dropdown-item>
             
            <b-dropdown-item aria-role="listitem">
              <download-excel :data="tableData" :fields="jsonFields" name="SentMessagesByProject.xls">
  Excel
</download-excel>
</b-dropdown-item>
        </b-dropdown>
            </div>
            </div>



</div>
    </section>
  </template>
  
<script>
  import { mapActions, mapGetters } from 'vuex'
  import axios from 'axios'

  export default {
    name: 'SentMessages',
    computed: mapGetters(['getRole', 'getWorkspaceId', 'getAuthHeader', 'allProjects']),
    created: function () {
      if (this.getRole !== 'Super Admin') {
        this.fetchProjectsByWorkspaceId(this.getWorkspaceId)
      } else {
        this.fetchProjects()
      }
      this.showProjects = this.allProjects
      this.showProjects = this.showProjects.filter(x => x.projectType === 'Send Messages' || x.projectType === 'Forward Messages')
    },
    data () {
      return {
        falseString: false,
        showProjects: [],
        perPage: 20,
        status: null,
        projectId: null,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        currentPage: 1,
        tableData: null,
        showData: false,
        dates: [],
        locale: 'en-GB',
        isBordered: true,
        isPaginated: true,
        columns: [
          {
              field: '_id',
              label: 'ID',
              // width: '40',
              // numeric: true
              centered: true
          },
          {
              field: 'time',
              label: 'Time',
              centered: true
          },
          {
              field: 'text',
              label: 'Message text',
              centered: true
          },
          {
              field: 'status',
              label: 'Status',
              centered: true
          },
          {
              field: 'sender',
              label: 'Sender',
              centered: true
          },
          {
              field: 'receiver',
              label: 'Receiver',
              centered: true
          },
          {
              field: 'type',
              label: 'Type',
              centered: true
          },
          {
              field: 'size',
              label: 'Size',
              centered: true
          }
        ],
        jsonFields: {
          "ID": "_id",
          "Time": "time",
          "Message text": "text",
          "Status": "status",
          "Sender": "sender",
          "Receiver": "receiver",
          "Type": "type",
          "Size": "size"
        },
      }
    },
    methods: {
      ...mapActions(['fetchProjects', 'fetchProjectsByWorkspaceId']),
      async searchClicked () {
        if (this.dates.length !== 2 || this.status === null || this.projectId === null) {
          this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Please fill in all fields.',
              confirmText: 'Ok!',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa'
          })
        } else {
          var loadingComponent = this.$buefy.loading.open()
          let startDateOriginal = this.dates[0].toLocaleDateString('en-GB')
          let endDateOriginal = this.dates[1].toLocaleDateString('en-GB')
          var startDateArray = startDateOriginal.split('/')
          var endDateArray = endDateOriginal.split('/')
          var startDate = startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0]
          var endDate = endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0]
          var projectType = this.allProjects.filter(x => x._id === this.projectId)[0].projectType
          this.globalLogger(startDate, endDate)
          var path = ''
          if (this.status === 'all' && projectType === 'Send Messages') {
              path = 'analytics/getSentMessage/' + this.projectId
              this.globalLogger('all sent')
          } else if (this.status === 'all' && projectType === 'Forward Messages') {
              path = 'analytics/getForwardedMessage/' + this.projectId
              this.globalLogger('all forw')
          } else if (this.status === 'success' && projectType === 'Send Messages') {
              path = 'analytics/getSuccessfulSentMessage/' + this.projectId
              this.globalLogger('success i send')
          } else if (this.status === 'success' && projectType === 'Forward Messages') {
              path = 'analytics/getSuccessfulForwardedMessage/' + this.projectId
              this.globalLogger('success i forw')
          } else if (this.status === 'fail' && projectType === 'Send Messages') {
              path = 'analytics/getUnsuccessfulSentMessage/' + this.projectId
              this.globalLogger('fail i send')
          } else if (this.status === 'fail' && projectType === 'Forward Messages') {
              path = 'analytics/getUnsuccessfulForwardedMessage/' + this.projectId
              this.globalLogger('fail i for')
          }
          try {
            const response = await axios.post('https://smsgw.virtual-service-center.com:8080/' + path, {
              startDate: startDate,
              endDate: endDate
            }, {
                headers: { 'Authorization': this.getAuthHeader }
            })
            loadingComponent.close()
            this.tableData = response.data
            var time = null
            var timeArray = null
            for (let obj in response.data) {
              time = this.tableData[obj].time
              timeArray = time.split('T')
              this.tableData[obj].time = timeArray[0] + ' ' + timeArray[1].substring(0, 8)
            }
            this.globalLogger(this.tableData, 'table')
            this.showData = true
          } catch (error) {
            loadingComponent.close()
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to fetch data.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
          }
        }
      }
    }
  }
</script>

<style>
</style>