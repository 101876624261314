import axios from 'axios'
// import { ToastProgrammatic as Toast } from 'buefy'
import { LoadingProgrammatic as Loading } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'

const path = 'https://smsgw.virtual-service-center.com:8080'

const state = {
    workspaces: []
}

const getters = {
    allWorkspaces: (state) => {
        return state.workspaces
    }
}

const actions = {
    async fetchWorkspaces({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/workspace/allWorkspaces', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setWorkspaces', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async addWorkspace({ commit, rootState }, payload) {
        var loading = Loading.open()
        try {
            const response = await axios.post(path + '/workspace/addWorkspace', {
                name: payload.name
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('newWorkspace', response.data)
            loading.close()
            Dialog.alert({
                title: 'Success',
                message: 'The workspace was successfully added.',
                confirmText: 'Ok!',
                type: 'is-success',
                hasIcon: true,
                iconPack: 'fa'
            })
        } catch (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to add workspace.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
    async editWorkspace({commit, rootState}, payload) {
        var loading = Loading.open()
        try {
            const response = await axios.put(`${path}/workspace/updateWorkspace?id=${payload._id}`, 
            {
                name: payload.name
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('updatedWorkspace', response.data)
            loading.close()
        } catch (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to edit workspace.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
    async deleteWorkspace({commit, rootState}, id) {
        var loading = Loading.open()
        try {
            await axios.delete(`${path}/workspace/deleteWorkspace?id=${id}`, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('delWorkspace', id)
            loading.close()
        } catch (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to delete workspace.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    }
}

const mutations = {
    setWorkspaces: (state, workspaces) => (state.workspaces = workspaces),
    newWorkspace: (state, workspace) => (state.workspaces.push(workspace)),
    delWorkspace: (state, id) => (state.workspaces = state.workspaces.filter(wrks => wrks._id !== id)),
    updatedWorkspace: (state, updWorkspace) => {
        const index = state.workspaces.findIndex(wrks => wrks._id === updWorkspace._id)
        if (index !== -1) {
            state.workspaces.splice(index, 1, updWorkspace)
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}