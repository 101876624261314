import axios from 'axios'

const path = 'https://smsgw.virtual-service-center.com:8080'

const state = {
    userRoleTypes: [],
    statusTypes: [],
    projectTypes: [],
    providers: []
}

const getters = {
    roles: (state) => {
        return state.userRoleTypes
    },
    statuses: (state) => {
        return state.statusTypes
    },
    projectTypes: (state) => {
        return state.projectTypes
    },
    providers: (state) => {
        return state.providers
    }
}

const actions = {
    async fetchRoles({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/data/userRoleTypes', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setUserRoles', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchStatuses({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/data/statusTypes', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setStatuses', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchProjectTypes({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/data/projectTypes', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProjectTypes', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchProviders({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/data/allProviders', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProviders', response.data)
        } catch (error) {
            // console.log(error)
        }
    }
}

const mutations = {
    setUserRoles: (state, userRoles) => (state.userRoleTypes = userRoles),
    setStatuses: (state, statuses) => (state.statusTypes = statuses),
    setProjectTypes: (state, projectTypes) => (state.projectTypes = projectTypes),
    setProviders: (state, providers) => (state.providers = providers)
}

export default {
    state,
    getters,
    actions,
    mutations
}